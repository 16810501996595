import React, { Component } from "react";


import Layout from "../components/layou_en"
import fondo1 from "../imagenes/fondo-pag-1_3.jpg"
import Pagination from "../components/others/Pagination"

import { StaticQuery, graphql } from "gatsby"

import Moment from "moment";
import en from "moment/locale/en-in";
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton
} from "react-share";

Moment.locale("en", en);
const objectGraphql = graphql`
{
  allStrapiNoticias(filter: {locale: {eq: "en"}}) {
   edges {
     node {
       id
      titulo
       
      Tags {
        nombre
        id
      }
      Categorys{
          nombre}
      fecha
      min_descripcion
      descripcion
      imagen {
        url
      }
     }
   }
 }
}
`


class New extends Component {
    constructor() {
        super();


        this.state = {
            totalItems: [],
            pageOfItems: []
        };

        this.onChangePage = this.onChangePage.bind(this);
    }
    category = (item) => {
        let text = "";
        text = item;
        if (item === "sustainability") {
            text = "Sustainability";
        } if (item === "news") {
            text = "News";
        } if (item === "humanTalent") {
            text = "Human talent";
        } if (item === "business") {
            text = "Business";
        }
        return text
    }
    onChangePage(pageOfItems) {
        const array = pageOfItems;
        const sortedArray = array.sort((a, b) => new Moment(b.node.fecha).format('YYYYMMDD') - new Moment(a.node.fecha).format('YYYYMMDD'))

        this.setState({ pageOfItems: sortedArray });
    }
    pagination(data) {
        const array = data.allStrapiNoticias.edges;
        const sortedArray = array.sort((a, b) => new Moment(b.node.fecha).format('YYYYMMDD') - new Moment(a.node.fecha).format('YYYYMMDD'))

        return (
            <Pagination pageSize={9} items={sortedArray} onChangePage={this.onChangePage} />
        )
    }
    render() {


        return (
            <StaticQuery
                query={objectGraphql}
                render={data => (
                    <React.Fragment>
                        <Layout props={this.props.props}>
                            <section className="titulo-int"
                                style={{ backgroundImage: "url(" + fondo1 + ")" }}
                            >
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 titulo-center-b text-center">
                                            <h2>News</h2>
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="/en">Home</a></li>
                                                <li className="breadcrumb-item active">News</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="padding-section-int">
                                <div className="container">
                                    <div className="row">
                                        {
                                            this.state.pageOfItems.map((item) => {
                                                return (
                                                    <div key={item.node.id} className="col-lg-4">
                                                        <div className="card">
                                                            <div className="card-block">
                                                                <div className="card-img">
                                                                    <a href={`/en/news/${(item.node.titulo).replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`} title="">
                                                                        <img

                                                                            src={item.node.imagen[0].url} alt={item.node.imagen[0].url} className="card-img-top" />
                                                                    </a>
                                                                    <div className="card-fecha">
                                                                        <p>  {Moment(item.node.fecha).format(
                                                                            "D"
                                                                        )}</p>
                                                                        <p>  {Moment(item.node.fecha).format(
                                                                            "MMMM"
                                                                        )}  </p>
                                                                    </div>
                                                                </div>
                                                                <div className="card-cont">
                                                                    <p className="cat">
                                                                        <a href={`/en/category/${item.node.Categorys.nombre.replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`}>{this.category(item.node.Categorys.nombre)}</a>
                                                                    </p>
                                                                    <h3 className="card-title">
                                                                        <a href={`/en/news/${(item.node.titulo).replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`}>
                                                                            {item.node.titulo}                     </a>
                                                                    </h3>
                                                                    <div className="parrafo-body">
                                                                        {item.node.min_descripcion}
                                                                    </div>
                                                                    <p className="a-none">
                                                                        <a href={`/en/news/${(item.node.titulo).replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`} style={{ marginTop: '15px', }} className="btn btn-secondary">
                                                                            <i className="fa fa-long-arrow-right"  ></i> Keep reading                      </a>
                                                                    </p>
                                                                    <p style={{display:'flex'}}>

                                                                        <FacebookShareButton
                                                                              
                                                                            url={this.props.props.location.host + "/en/news/" + (item.node.titulo).replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}
                                                                        >
                                                                            <div className='redes fac' >
                                                                                <i className="fa fa-facebook"  ></i>
                                                                            </div>
                                                                        </FacebookShareButton>



                                                                        <TwitterShareButton
                                                                            title={item.node.titulo}
                                                                            url={this.props.props.location.host + "/en/news/" + (item.node.titulo).replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}
                                                                        >
                                                                            <div className='redes twi' >
                                                                                <i className="fa fa-twitter"  ></i>
                                                                            </div>
                                                                        </TwitterShareButton>



                                                                        <LinkedinShareButton className='redes lin'
                                                                            title={item.node.titulo}
                                                                            summary={item.node.min_descripcion}
                                                                            url={this.props.props.location.host + "/en/news/" + (item.node.titulo).replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}
                                                                        >
                                                                            <div className='redes lin' >
                                                                                <i className="fa fa-linkedin"  ></i>
                                                                            </div>
                                                                        </LinkedinShareButton>



                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                )
                                            })
                                        }

                                        <div className="item-list col-md-12 titulo-center text-center">

                                            {this.pagination(data)}

                                        </div>


                                    </div>
                                </div>
                            </section>

                        </Layout>
                    </ React.Fragment>
                )}
            />
        )
    }
}

export default New
